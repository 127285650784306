<template>
  <div class="card-holder">
    <el-card>
      <h3>{{ $t('ForceResetPasswordPageCardTitle') }}</h3>
      <el-row >
          <el-col :span="10" :offset="isRTL?2:0">
            <el-input
              :placeholder="$t('ForceResetPasswordPageFieldLabelNewPassword')"
              v-model="newPassword"
              clearable
            >
            </el-input>
          </el-col>
          <el-col :span="12" :offset="isRTL?0:2" >
            <div class="u-bottom-margin--2x" v-for="(singlePhoneObj,index) in phoneNumbersArray" :key="singlePhoneObj.id">
              <el-row :gutter="20">
                <el-col :span="21" >
                  <el-input
                    :placeholder="$t('ForceResetPasswordPageFieldLabelPhoneNumber')"
                    v-model="singlePhoneObj.value"
                    clearable
                  >
                  </el-input>
                </el-col>
                <el-col :span="3" >
                  <el-button 
                    v-if="index==0" 
                    type="success" 
                    icon="el-icon-plus" 
                    circle
                    size="small"
                    @click="addFieldClicked"
                  ></el-button>
                  <el-button 
                    v-else 
                    type="danger" 
                    icon="el-icon-minus" 
                    circle
                    size="small"
                    @click="removePhoneNumber(singlePhoneObj.key)"
                  ></el-button>
                </el-col>
              </el-row>        
            </div>
          </el-col>
      </el-row>

      <div class="submit-button-container">
        <el-button
          class="login-button"
          type="primary"
          block
          @click="submitButtonClicked"
        >
          {{ $t('ForceResetPasswordPageButtonLabelSubmit') }}
        </el-button>
      </div>
      
    </el-card>
  </div>
</template>

<script>
import { adminActions } from "@/services";

export default {
    name:"ForceResetPassword",
    data(){
      return {
        phoneNumbersArray:[
          {
            key:"0",
            value:""
          }
        ],
        newPassword:null
      }
    },
    methods:{
      addFieldClicked(){
        const lastObject = JSON.parse(JSON.stringify(this.phoneNumbersArray[this.phoneNumbersArray.length-1]))
        let newObject = {
          value:"",
          key: lastObject.key + this.phoneNumbersArray.length
        }
        this.phoneNumbersArray.push(newObject)
      },
      removePhoneNumber(clickedRowId){
        const newArray = this.phoneNumbersArray.filter(singleRow=>{
          return clickedRowId !== singleRow.key
        })
        this.phoneNumbersArray = newArray
      },
      async submitButtonClicked(){
        let enteredPhoneNumbers = []
        this.phoneNumbersArray.forEach(singlePhoneObj=>{
          if(singlePhoneObj.value && singlePhoneObj.value.length>0){
            enteredPhoneNumbers.push(singlePhoneObj.value)
          }
        })
        let currentEnteredPassword = this.newPassword
        const apiObject = {
          users:enteredPhoneNumbers,
          secret:currentEnteredPassword
        }
				this.submit(apiObject)
      },
			submit(formData){
				this.$loading()
				adminActions
          .ForceResetPassword(formData)
          .then(
            (res) => {
              let responseData = res.data.data;
							const rejectedUsers = responseData.rejected.map(rejected=>{
								return rejected.reason.key
							})
							if(rejectedUsers.length > 0){
								const errorMessage = `${this.$t("ForceResetPasswordPageSendCatchErrorMessage")} ${rejectedUsers}`
								this.popupMessageWrapper(errorMessage,"error",9000)
							}
							else{
								this.popupMessageWrapper(this.$t("ForceResetPasswordPageSendSuccessMessage","success",3000))
							}
						}
					)
					.catch((e) => {
						console.log("error", e);
						this.popupMessageWrapper(this.$t("ForceResetPasswordPageSendCatchErrorMessage"),"error",5000)
          })
          .finally(() => {
            this.$loading().close();
						this.resetData()
          });
			},
      popupMessageWrapper(message='',type='success',duration=1000){
        this.$message({message,type,duration})
      },
			resetData(){
				this.phoneNumbersArray = [ { key:"0",value:"" } ]
        this.newPassword=null
			}
    },
    computed:{
      isRTL(){
        return this.$store.state.isRTL
      }
    }
}
</script>

<style lang="scss" scoped>
.el-card {
  width: 800px;
}
.el-select{
  width: 100%;
}
.submit-button-container{
  display: flex;
  justify-content: flex-end;
}
.card-holder{
  display: flex;
  justify-content: center;
}
</style>